/* Todo: Remove importants when we can remove the old button without everything breaking....*/

.button-main {
  color: #ffffff;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  border: 0px solid #fff !important;
  box-shadow: #fff !important;
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding: 3px 15px;
  border-radius: 8px;
  transition: .5s ease-in-out;
  outline: none !important;
  border: none;
}

.button-main:hover {
  filter: opacity(.8);
}

.button-main:active {
  filter: brightness(1.1);
}

.button-main:disabled {
  cursor: default;
}

.button-primary {
  background-color: #1C8E47 !important;
}

.button-secondary {
  background-color: #ce3c25;
}

.button-tertiary {
  background-color: #a5a5a5;
  color: #2f2f2f;
}

.button-quaternary {
  background-color: #fff;
  color: #ce3c25;
}

.button-outline {
  background: none !important;
  border: 1px solid #000 !important;
  color: #000;
}

.button-disabled {
  background-color: #a5a5a5 !important;
  color: #2f2f2f !important;
}

.button-icon {
  height: 20px;
  width: 19px;
  margin: 0px 10px 0px 0px;
}

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  gap: .5rem;
}

.button-just-text {
  padding: 0px 25px;
}
